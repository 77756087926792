// @ts-ignore
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)},
  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)},
  { path: 'login', loadChildren: () => import('./pages/menu/login/login.module').then(m => m.LoginPageModule)},
  { path: 'tasks', loadChildren: () => import('./pages/menu/tasks/tasks.module').then(m => m.TasksPageModule)},
  { path: 'settings', loadChildren: () => import('./pages/menu/settings/settings.module').then(m => m.SettingsPageModule)},
  { path: 'article/:articleId/:title', loadChildren: './pages/article/article.module#ArticlePageModule' },
  { path: 'add-image', loadChildren: () => import('./pages/add-image/add-image.module').then(m => m.AddImagePageModule)},
  { path: 'attach', loadChildren: './pages/attach/attach.module#AttachPageModule' },
  { path: 'avatars', loadChildren: () => import('./pages/menu/settings/avatars/avatars.module').then( m => m.AvatarsPageModule)},
  { path: 'contact/:title', loadChildren: () => import('./pages/menu/contact/contact.module').then(m => m.ContactPageModule) },
  { path: 'edit-task', loadChildren: './pages/menu/tasks/edit-task/edit-task.module#EditTaskPageModule' },
  { path: 'group-settings/:groupId', loadChildren:
        './pages/home/group-list/group-room/group-settings/group-settings.module#GroupSettingsPageModule'},
  { path: 'report', loadChildren: './pages/report/report.module#ReportPageModule' },
  { path: 'group-room/:groupId', loadChildren: () => import('./pages/home/group-list/group-room/group-room.module').then(m => m.GroupRoomPageModule) },

  { path: 'chat-room/:chatId', loadChildren: './pages/home/chat-list/chat-room/chat-room.module#ChatRoomPageModule' },
  { path: 'add-msg', loadChildren: './pages/add-msg/add-msg.module#AddMsgPageModule' },
  { path: 'survey-users', loadChildren: './pages/home/survey-users/survey-users.module#SurveyUsersPageModule' },
  { path: 'trace-users', loadChildren: './pages/home/trace-users/trace-users.module#TraceUsersPageModule' },
  { path: 'tasks-users', loadChildren: './pages/home/tasks-users/tasks-users.module#TasksUsersPageModule' },
  { path: 'dashboard-users', loadChildren: './pages/home/dashboard-users/dashboard-users.module#DashboardUsersPageModule' },
  { path: 'group-toa/:groupId', loadChildren: './pages/home/group-list/group-room/group-toa/group-toa.module#GroupToaPageModule' },
  { path: 'item/:itemid', loadChildren: './pages/news/item/item.module#ItemPageModule' },
  { path: 'history', loadChildren: './pages/menu/history/history.module#HistoryPageModule' },
  {
    path: 'mood',
    loadChildren: () => import('./pages/home/mood/mood.module').then( m => m.MoodPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
