import {Component, Input, OnInit} from '@angular/core';
import {Question, Survey, SurveyService} from '../../../../services/survey.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage';

class ViewController {
}

@Component({
  selector: 'app-survey-item',
  templateUrl: './survey-item.page.html',
  styleUrls: ['./survey-item.page.scss'],
})
export class SurveyItemPage implements OnInit {
  @Input() survey: Survey;
  public questions: Question[];
  public step = 0;
  public steps = 0;
  public surveyForm: FormGroup;
  constructor(public navCtrl: NavController,
              private fb: FormBuilder,
              private alertController: AlertController,
              public storage: Storage,
              // private navParams: NavParams,
              public translate: TranslateService,
              public surveyProv: SurveyService,
              public modalCtrl: ModalController) {
    this.surveyForm = this.fb.group({
      surveyId: new FormControl('')
    });

  }

  ngOnInit() {
    this.questions = JSON.parse(this.survey.questions);
    console.log(this.questions);
    this.surveyForm.controls.surveyId.setValue(this.survey.id);

    Object.keys(this.questions).sort().forEach(key => {
      this.surveyForm.addControl(key, new FormControl('', Validators.required));
      this.steps++;
    });
    this.storage.get('survey_' + this.survey.id).then(exists => {
    if ( exists ) {
        this.step = exists.step;
        let i = exists.step;
        Object.keys(exists.values).map(q => {
          if (i-- >= 0) {
            console.log(q, exists.values[q]);
            // @ts-ignore
            this.surveyForm.controls[q].setValue( exists.values[q]);
          }
        });
        console.log(this.surveyForm.value);
      }
    });
  }
  async nextStep() {
    if (this.surveyForm.value['questions' + this.step]) {
      this.step++;
      this.storage.set('survey_' + this.survey.id, {step: this.step, values: this.surveyForm.value});

    } else {
      const alert = await this.alertController.create({
        header: this.translate.instant('REQUIRED'),
        message: this.translate.instant('REQUIRED_FIELD'),
        buttons: ['OK']
      });

      await alert.present();
    }

    if (Object.keys(this.questions).length - this.step === 0) {
      this.doSubmit();
    }

  }
  doSubmit() {
    this.surveyProv.submitSurvey( this.survey.id, this.surveyForm.value).then(() => {
      //  console.log(this.surveyForm);
      this.modalCtrl.dismiss({
        dismissed: true
      });
    });


  }

}
