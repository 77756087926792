import { enableProdMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import localeHe from '@angular/common/locales/he';
import localeHeExtra from '@angular/common/locales/extra/he';

import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import firebase from 'firebase/app/';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';

import {IonicStorageModule} from '@ionic/storage';
import {SurveyService} from './services/survey.service';
import {TraceService} from './services/trace.service';
import {ScoreService} from './services/score.service';
import {ComponentsModule} from './components/components.module';
import {SubmenuPage} from './pages/submenu/submenu.page';
import {SubmenuPageModule} from './pages/submenu/submenu.module';
import {AttachPageModule} from './pages/attach/attach.module';
import {SurveyItemPageModule} from './pages/home/survey/survey-item/survey-item.module';
import {HttpApiService} from './services/http-api.service';
import {UserService} from './services/user.service';


// @ts-ignore
import {registerLocaleData} from '@angular/common';
import {ChatService} from './services/chat.service';
import {EmojiService} from './services/emoji.service';
import {ImageService} from './services/image.service';
import {PushService} from './services/push.service';
import {StatisticsService} from './services/statistics.service';
import {TasksService} from './services/tasks.service';
import {AddImagePage} from './pages/add-image/add-image.page';
import {AddImagePageModule} from './pages/add-image/add-image.module';
import {Camera} from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import {RelativeTimePipe} from './pipes/relative-time.pipe';
import {PipesModule} from './pipes/pipes.module';
import {LoginPageModule} from './pages/menu/login/login.module';
import {AddMsgPageModule} from './pages/add-msg/add-msg.module';
import { FirebaseX} from '@ionic-native/firebase-x/ngx';
import {Angular7PincodeModule} from 'angular7-pincode';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {AddMoodPageModule} from './pages/home/mood/add-mood/add-mood.module';


export function LanguageLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const firebaseConfig = {
    apiKey: 'AIzaSyA7kKN-1gIscUg3VLd0Ub4U8oS6uocA6zM',
    authDomain: 'makshivimapp.firebaseapp.com',
    databaseURL: 'https://makshivimapp.firebaseio.com',
    projectId: 'makshivimapp',
    storageBucket: 'makshivimapp.appspot.com',
    messagingSenderId: '260257239404',
    appId: '1:260257239404:web:0970173c5bca4a45a76349',
    measurementId: 'G-TMS79JG06N'

};
// const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);


registerLocaleData(localeHe, localeHeExtra);
enableProdMode();
// @ts-ignore
// @ts-ignore
@NgModule({
    declarations: [AppComponent],
    entryComponents: [
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ComponentsModule,
        PipesModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (LanguageLoader),
                deps: [HttpClient]
            }
        }),
        Angular7PincodeModule,
        SubmenuPageModule,
        AttachPageModule,
        SurveyItemPageModule,
       AddImagePageModule,
        LoginPageModule,
        AddMsgPageModule,
        AddMoodPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        IonicStorageModule,
        SurveyService,
        TraceService,
        ScoreService,
        HttpApiService,
        UserService,
        ChatService,
        EmojiService,
        ImageService,
        PushService,
        ScoreService,
        StatisticsService,
        TasksService,
        PhotoViewer,
        Camera,
        AndroidPermissions,
        File,
        FirebaseX,
        FileTransfer,
        FileOpener,

        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    exports: [
    ],

    bootstrap: [AppComponent]
})
export class AppModule {}
