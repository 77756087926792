import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {EmojiPickerComponent} from './emoji-picker/emoji-picker.component';
import {BrowserModule} from '@angular/platform-browser';
import {SelectIconComponent} from './select-icon/select-icon.component';



@NgModule({
  declarations: [EmojiPickerComponent, SelectIconComponent],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [EmojiPickerComponent
  ]
})
export class ComponentsModule { }
