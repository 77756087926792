import { Injectable } from '@angular/core';
import {HttpApiService} from './http-api.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private httpApi: HttpApiService, private userServ: UserService) { }


  hit(type) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'statistics',
      func: 'hit',
      type,
    };
    this.httpApi.get('ARCHAT', data, {}, false);
  }

  tos(version) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'statistics',
      func: 'tos',
      version
    };
    this.httpApi.get('ARCHAT', data, {}, false);
  }
}
