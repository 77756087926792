import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserInfo, UserService} from './user.service';
import { Platform, ToastController} from '@ionic/angular';
import {HttpApiService} from './http-api.service';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/firestore';

import {PushService} from './push.service';
import {Storage} from '@ionic/storage';
import {StatisticsService} from './statistics.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';

export class ChatMessage {
  key: any;
  messageId: string;
  userId: string;
  userName: string;
  userAvatar: string;
  toUserId: string;
  time: any;
  message: string;
  status: string;
  ref: any;
  type: string;
  publish: any = 1;
  msgConnect ?  = '';
  content ? = 'text';
  admin = false;
  conversationId?: string;
  groupname?: string;
  rewrite?: boolean;
  day = '';
  usertype = 'user';
  loading?: boolean;
}

export class ToUserInfo {
  id: string;
  name?: string;
  avatar?: string;
  type: string;
  // tslint:disable-next-line:variable-name
  user_id?: string;
}
export class ToGroupInfo {
  id: string;
  name =  '';
  avatar?: string;
  type: string;
  item?: any;
}

export class GroupSettings {
  id: string;
  name?: string;
  avatar?: string;
  type: string;
  admin ? = false;
  block = true;
  users?: any[];
  hours?: string;
  supervisor?: number;
  // tslint:disable-next-line:variable-name
  alias_name?: string;
  intro? :string;
}

export class Conversation {
  partner: string;
  badge: number;
  lastmsg: string;
  name: string;
  avatar: string;
  conversationId: string;
  id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public groups;
  public groupsObj: any = {};
  public chats: any[] = [];
  public chatBadge = 0;
  public serverBaseHref;
  // public firebase = (<any>window).FirebasePlugin;
  constructor(private http: HttpClient,
              private userServ: UserService,
              private httpApi: HttpApiService,
              public platform: Platform,
              public storage: Storage,
              public pushServ: PushService,
              public statisticsService: StatisticsService,
              public toastController: ToastController,
              public translate: TranslateService,
              public photoViewer: PhotoViewer,
              //  private firebaseMsg: FirebaseMessaging,
              ) {
    this.serverBaseHref = environment.serverBaseHref;
    // this.getGroupList();
    // this.getUsersUnreadList();
  }


  sendMsg(msg: ChatMessage, touser: ToUserInfo) {
    // console.log(touser);
    if (msg.type === 'group') {
      this.sendGroupMsg(msg);
    }
    if (msg.type === 'user') {
      this.sendUserMsg(msg);
    }
  }
  sendUserMsg(msg: ChatMessage) {

    console.log(msg);
    console.log('chats/' + msg.conversationId + '/messages');

    firebase.firestore().collection('chats/' + msg.conversationId + '/messages').add(msg).then(snap => {
      // console.log(snap);
      // console.log('users/' + msg.toUserId + '/conversations/' + msg.userId);
      firebase.firestore().doc('users/' + msg.toUserId + '/conversations/' + msg.userId).update({
        badge: firebase.firestore.FieldValue.increment(1),
        name: msg.userName,
        avatar: msg.userAvatar,
        partner: msg.userId,
        lastmsg: msg.content === 'image' ? '📷' : msg.message,
        time: msg.time
      });
      firebase.firestore().doc('users/' + msg.userId + '/conversations/' + msg.toUserId).update({
        lastmsg: msg.content === 'image' ? '📷' : msg.message,
        time: msg.time
      });
    });
    const topic = 'CHAT_' + msg.conversationId;
    if (msg.content === 'image') {
      msg.message = '📷';
    }
    if (msg.content === 'video') {
      msg.message = '📹';
    }
    this.sendPush('user', topic , msg, 'chat-room/' + msg.conversationId);
  }

  getConversationId(user1, user2) {
    const docRef = firebase.firestore().doc('users/' + user1.userid + '/conversations/' + user2.userid);
    return new Promise(resolve => {
      docRef.get().then((conversationExists) => {
        if (conversationExists.exists) {
          console.log(conversationExists.data());
          resolve(conversationExists.data());
          // console.log('Document data:', conversationExists.data());
        } else {
          user2.id = user2.userid;
          user2.avatar = '';
          this.createChat(user1, user2).then(conversation => {
            resolve(conversation);
          });
        }
      });
    });
  }
  createChat(user1, user2) {
    // console.log('createChat', user1, user2);
    const docRef = firebase.firestore().doc('users/' + user1.userid + '/conversations/' + user2.id);
    const doc2Ref = firebase.firestore().doc('users/' + user2.id + '/conversations/' + user1.userid);
    return new Promise(resolve => {
      firebase.firestore().collection('chats').add({messages: {}}).then((conversation) => {
        //   console.log(conversation);
        this.pushServ.subscribeToTopic('CHAT_' + conversation.id, user2.id);
        docRef.set({
          partner: user2.id,
          badge: 0,
          lastmsg: '',
          name: user2.name,
          avatar: user2.avatar,
          conversationId: conversation.id
        });
        doc2Ref.set({
          partner: user1.userid,
          badge: 0,
          lastmsg: '',
          name: user1.name,
          avatar: user1.avatar,
          conversationId: conversation.id
        });
        resolve({
          partner: user2.id,
          badge: 0,
          lastmsg: '',
          name: user2.name,
          avatar: user2.avatar,
          conversationId: conversation.id
        });
      });
    });

    // console.log('users/'+user1+'/conversations/'+user2);
    // firebase.firestore().doc('users/'+user1+'/conversations/'+user2).get().then(conversation => {
    //   console.log(conversation);
    // })
    // firebase.firestore().collection("chats").add({}).then(conversationId => {
    //   console.log(conversationId);
    //   //firebase.firestore().collection('users/'+user1+'/conversations')
    // })
  }

  sendGroupMsg(msg: ChatMessage) {
    // console.log(msg);
    firebase.firestore().doc('groups_chats/' + msg.toUserId).collection('messages').add(msg);
    // firebase.firestore().doc('groups_chats/'+msg.toUserId).update({
    //   messages: firebase.firestore.FieldValue.arrayUnion(msg)
    // });
    // console.log('groups_chats/'+msg.toUserId);
    // console.log(firebase.firestore.FieldValue.arrayUnion(msg));
    if (msg.content === 'image') {
      msg.message = '📷';
    }
    if (msg.content === 'video') {
      msg.message = '📹';
    }
    this.sendPush('group', 'GROUP_' + msg.toUserId, msg, 'group-room/' + msg.toUserId, msg.toUserId);
  }

  getUsersChat() {
    // console.log(this.userServ.userInfo);
  }

  blockUser(groupId, groupalias, dennyUser, denny) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'denny_user',
      dennyUser,
      group: groupId,
      denny,
      groupalias,
    };

    this.httpApi.get('ARCHAT', data, {}, false).then(async res => {
      // console.log(res);
      const toast = await this.toastController.create({
        message: denny ? this.translate.instant('DENNY_SECCESS') : this.translate.instant('ALOW_SECCESS'),
        duration: 2000,
        position: 'top'
      });
      toast.present();
    });
    // firebase.database().ref('groups_details/'+groupAlias+'/block/'+userid).set(denny);
  }


  //
  // sendChatMsg(msg: ChatMessage, touser: ToUserInfo) {
  //
  //   let tt = firebase.database().ref('chats/'+msg.userId+'/'+msg.toUserId+'/msg').push(msg);
  //
  //   msg.ref = 'chats/'+msg.userId+'/'+msg.toUserId+'/msg/'+tt.key;
  //   firebase.database().ref('chats/'+msg.toUserId+'/'+msg.userId+'/msg').push(msg);
  //   let badgeChat  = firebase.database().ref('chats/'+msg.toUserId+'/'+msg.userId+'/badge');
  //   badgeChat.once('value')
  //     .then(function(snapshot) {
  //       if(snapshot.val()){
  //         badgeChat.set(snapshot.val()+1);
  //       }
  //       else
  //         badgeChat.set(1);
  //
  //     });
  //   let badgeGeneral  = firebase.database().ref('chats/'+msg.toUserId+'/badge');
  //   badgeChat.once('value')
  //     .then(function(snapshot) {
  //       if(snapshot.val()){
  //         badgeGeneral.set(snapshot.val()+1);
  //       }
  //       else
  //         badgeGeneral.set(1);
  //
  //     });
  //
  // }

  // getGroupsObj(){
  //   return this.groupsObj;
  // }

  // private toArray(obj) : any[] {
  //   const arr = [];
  //
  //   if(obj){
  //     Object.keys(obj).forEach(key => {
  //
  //       if(key != 'badge')
  //         arr.push(obj[key]);
  //     });
  //   }
  //
  //   return arr;
  // }

  public chatToArray(obj): any[] {

    const arr = [];

    if (obj) {

      Object.keys(obj).forEach(key => {
        //  console.log(obj[key].publish);
        const user = obj[key].userId;
        obj[key].userId = obj[key].userId;
        obj[key].key = key;

        if (obj[key].publish === 1 ) {
          arr.push(obj[key]);
        }
      });
    }
    // console.log(arr);
    return arr;
  }

  getGroupList() {

    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_group_list',
    };
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        this.groups = res;
        // console.log(this.groups);
        resolve(res);
      });
    });
  }

  public getGroup(user: UserInfo, group) {
    if (user) {
      const data = {
        option: 'com_archat_api',
        task: 'api',
        func: 'get_group',
        group
      };
      // console.log(user);
      // @ts-ignore
      return new Promise(resolve => {
        this.httpApi.get('ARCHAT', data, {}, false).then((res) => {
          // console.log(res);
          resolve(res);

        });
      });
    }



  }

  deleteMsg(msgId, groupId, groupalias, deleteType) {
    // console.log('groups_chats/' + groupalias + '/messages/' + msgId + '/');
    firebase.firestore().doc('groups_chats/' + groupalias + '/messages/' + msgId + '/').update({publish: deleteType});
  }

  sendPush(type, topic , msg, url, groupid?) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'push',
      func: 'push_topic',
      message: msg.message,
      title: msg.userName,
      topic,
      url
    };
    if (msg.groupname) {
      data.title = data.title + ' (' + msg.groupname + ')';
    }
    this.httpApi.get('ARCHAT', data, {}, false, false).then(res => {
      //   console.log(res);

    });
    this.statisticsService.hit('message:' + groupid);
  }
  getUsersUnreadList() {
    console.log('getUsersUnreadList');
    return new Promise(resolve => {
      firebase.firestore().collection('users/' + this.userServ.userInfo.userid + '/conversations').onSnapshot(snap => {
        this.chats = [];
        this.chatBadge = 0;
        if (this.groups) {
          this.groups.map(group => {
            group.badge = 0;
          });
        }
        snap.docs.forEach(entry => {
          // console.log(entry.data());
          const conversation = new Conversation();
          Object.keys(entry.data()).map((key) => {
            conversation[key] = entry.data()[key] ;
          });
          this.chatBadge += conversation.badge;
          if (this.groups) {
            this.groups.map(group => {
              group.users.map(user => {
                if (user.id === conversation.partner) {
                  user.conversationId = conversation.conversationId;
                }
                // console.log(user, conversation);
                if (user.id === conversation.partner && !isNaN(conversation.badge) ) {
                  // console.log(conversation.badge);
                  user.badge = conversation.badge;
                  group.badge += conversation.badge;
                }
              });
            });
          }

          this.chats.push(conversation);
        });

        console.log(this.groups);
        // console.log(this.chats);
        resolve(1);
      });

    });
  }

  getChatMeta(conversationId) {
    return this.chats.filter(item => item.conversationId === conversationId )[0];
  }

  // const usersref = firebase.database().ref('/chats/'+this.userProv.userInfo.userid).orderByChild('/badge/').startAt(1);
  // usersref.on('value', result =>{
  //   this.chats = [];
  //   if(result.val()){
  //     Object.keys(result.val()).forEach(key => {
  //       console.log(result.val()[key] );
  //
  //       const chat = result.val()[key].msg;
  //       const lastMsg = chat[Object.keys(chat)[Object.keys(chat).length-1]];
  //       console.log(lastMsg);
  //       lastMsg.id = key;
  //       lastMsg.name = lastMsg.userName;
  //       this.chats.push(lastMsg);
  //     });
  //   }
  //
  //
  // });






  setPush(topic) {



    this.storage.get(topic).then(val => {
      if (val) {
        this.pushServ.subscribeToTopic(topic, this.userServ.userInfo.userid);
        //
        //       this.firebaseMsg.unsubscribe(topic).then(res=>{
        //         console.log(res);
        //         console.log("unsubscribe", topic);
        //       });
      } else {
        this.pushServ.unsubscribeToTopic(topic, this.userServ.userInfo.userid);
        //       this.firebaseMsg.subscribe(topic).then(res=>{
        //         console.log("subscribe", topic);
        //       });
      }
      //
    });


  }


  openImage(url) {
    url = this.serverBaseHref + '/index.php?option=com_archat_api&task=api&func=getImage&image=' + url;
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.photoViewer.show(url);
    } else {
      const win = window.open(url, '_blank');
      win.focus();
    }

    // console.log(url);
  }
  openVideo(url) {
    // console.log(url);
    const link = 'https://www.youtube.com/embed/' + this.ytVideoId(url);
    const videoPlayer = document.getElementById( 'videoPlayer' );
    videoPlayer.innerHTML = '<iframe frameborder="0" allowfullscreen="1"' +

        ' allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" ' +
        ' width="100%" height="200px" src="' + link +
        '?cc_load_policy=1&iv_load_policy=3&controls=0&playsinline=1&rel=0&modestbranding=0&autoplay=1&enablejsapi=1&widgetid=1"' +
        ' frameborder="0" ></iframe>';
    document.getElementById( 'videoClose' ).classList.add('show');
    // this.video.openVideo(this.ytVideoId(url));

  }

  ytVideoId(url) {
    // tslint:disable-next-line:max-line-length
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    // @ts-ignore
    return (url.match(p)) ? RegExp.$1 : false;

  }
  ytVideoThumb(url) {
    const t = this.ytVideoId(url);
    return 'https://img.youtube.com/vi/' + t + '/hqdefault.jpg';
  }

  retryImg(msg) {
    msg.message = msg.message.replace('.', '_');
    msg.loading = true;
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'checkImg',
      image: msg.message
    };
    let i = 0;
    const interval = setInterval(() => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        if (res === 'exist') {
          msg.message = msg.message + '&i=' + i;
          clearInterval(interval);
          msg.loading = false;
        } else {
          i++;
          if (i === 10) {
            clearInterval(interval);
          }
        }
      }).catch(() => {
        clearInterval(interval);
      });
    }, 100000);
  }

}
