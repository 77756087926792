import { Component } from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {UserInfo, UserService} from './services/user.service';
import {Storage} from '@ionic/storage';
import {PushService} from './services/push.service';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
declare var wkWebView: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    { title: 'TASKS', url: 'tasks', icon: 'chat-star_border' },
    { title: 'TASKS_HISTORY', url: 'history', icon: 'chat-check1', params: {} },
    // { title: 'HOME', url: 'home', icon: 'person_outline', params: {} },
    { title: 'SETTINGS', url: 'settings', icon: 'chat-settings', params: {} },
    { title: 'PRIVACY', url: 'article/3/PRIVACY', icon: 'chat-security'},
    { title: 'TERMS', url: 'article/2/TERMS', icon: 'chat-description1'},
    { title: 'CONTACT', url: 'contact/WRITE_US', icon: 'chat-mail_outline' },
    { title: 'LOGOUT', url: 'login', icon: 'chat-logout', params: {articleId: 2, title: 'TERMS' }},

  ];
  baseHref = environment.serverBaseHref;
  constructor(
      public userServ: UserService,
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      public translate: TranslateService,
      public storage: Storage,
      private router: Router,
      private navCtrl: NavController,
      public pushServ: PushService
  ) {
    // this.storage.get('user').then((user: UserInfo) => {
    //   if (user) {
    //     this.userServ.loginToken(user).then(() => {
    //       this.initializeApp();
    //     });
    //   } else {
    //     this.initializeApp();
    //   }
    // });
    this.initializeApp();
    translate.setDefaultLang('he');
    translate.use('he');
  }
  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.show();
      this.statusBar.backgroundColorByHexString('#5FC3D2');
      this.router.navigateByUrl('/');
      // this.splashScreen.hide();
      this.pushServ.getMyTopics();
      this.platform.backButton.subscribe(() => {
        this.navCtrl.back();
      });
      if (this.platform.is('ios')) {
        //  wkWebView.injectCookie('chat.makshivim.net');
      }
      this.platform.resume.subscribe(async () => {
        console.log('resume re login');
        await this.userServ.loginToken(this.userServ.userInfo);
      });
    });
  }
}
