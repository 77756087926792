import { Component, OnInit } from '@angular/core';
import {ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {ImageService} from '../../services/image.service';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {Route} from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.page.html',
  styleUrls: ['./add-image.page.scss'],
})
export class AddImagePage implements OnInit {
  base64img: any = '';
  file;
  type;
  constructor(public imgServ: ImageService , public nav: NavController,
              private androidPermissions: AndroidPermissions,
              public userServ: UserService,
              public modalCtrl: ModalController, public navParams: NavParams,
              public platform: Platform, private camera: Camera) {

  }


  ionViewDidEnter() {

    // console.log('android', this.platform.is('android'));
    // console.log('cordova', this.platform.is('cordova'));
    // console.log('ios', this.platform.is('ios'));
    // console.log('iphone', this.platform.is('iphone'));
    // console.log('phablet', this.platform.is('phablet'));
    // console.log('tablet', this.platform.is('tablet'));
    // console.log('electron', this.platform.is('electron'));
    // console.log('pwa', this.platform.is('pwa'));
    // console.log('mobile', this.platform.is('mobile'));
    // console.log('mobileweb', this.platform.is('mobileweb'));
    // console.log('desktop', this.platform.is('pwa'));
    // console.log('hybrid', this.platform.is('mobile'));
    // console.log('mobileweb', this.platform.is('mobileweb'));
    this.type = this.navParams.get('type');
    if (this.type === 'images') {
      this.imageCapturedGallery();
    }
    if (this.type === 'camera') {
      this.imageCaptured();
    }
  }
  changeListener($event) {
    this.file = $event.target.files[0];
    this.getBase64(this.file);
    console.log(this.base64img);
  }

  imageCaptured() {
    // this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        result => {
          // console.log(result);
          if ( result.hasPermission === false ) {
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
          }
        },
        err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    );

    const options: CameraOptions = {
      quality: 50,
      allowEdit: false,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      targetWidth: 400,
      targetHeight: 600,

      // allowEdit: true
    };
    console.log('this.camera.getPicture');
    // alert('open camera');
    try {
      this.camera.getPicture(options).then((ImageData => {
        // alert(' sent ' + ImageData.length);

        this.base64img = 'data:image/jpeg;base64,' + ImageData;
      }), error => {
        console.log(error);
        // alert(error);
      });
    } catch (exeption) {
      // alert(exeption);
    }
  }

  imageCapturedGallery() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      saveToPhotoAlbum: false
    };
    this.camera.getPicture(options).then((ImageData => {
      this.base64img = 'data:image/jpeg;base64,' + ImageData;
    }), error => {
      console.log(error);
    });
  }
  nextPage() {

    // this.imgServ.setImage(this.base64img);
    if ( this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.imgServ.uploadImage(this.file).then(link => {
        console.log(link);
        if (typeof link === 'string') {
          //   console.log(link);
          console.log('mobileweb');
          console.log(JSON.parse(link));
          this.modalCtrl.dismiss(JSON.parse(link).value);
        }

      });
    } else {
      // this.imgServ.setImage(this.base64img);
      //  this.imgServ.uploadPic(this.base64img).then(link => {
      //    if (typeof link === 'string') {
      //      console.log('uploadPic');
      //      console.log(link);
      //  //    console.log(JSON.parse(link));
      //      this.modalCtrl.dismiss(link);
      //    }
      //  });

      this.imgServ.uploadImage(this.base64ToBlob(this.base64img, 'image/jpeg')).then(link => {
        console.log(link);
        if (typeof link === 'string') {
          this.modalCtrl.dismiss(JSON.parse(link).value);
        }

      });

    }


  }

  clear() {
    this.base64img = '';
    // this.nav.back();
  }


  ngOnInit() {
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64img = reader.result;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  base64ToBlob(base64, mime) {
    mime = mime || '';
    const sliceSize = 500;
    const byteChars = window.atob(base64.replace(/^[^,]+,/, ''));
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: mime});
    // alert(blob.size);
    return blob;
  }



}
