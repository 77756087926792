import {Attribute, Component, Directive} from '@angular/core';

import {UserService, UserInfo} from '../../../services/user.service';
import {Storage} from '@ionic/storage';
import {TranslateService} from '@ngx-translate/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import {HttpApiService} from '../../../services/http-api.service';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {AlertController, LoadingController, NavController, Platform} from '@ionic/angular';
import {PushService} from '../../../services/push.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage  {
  pattern: string;
  phone: any;
  code: any;
  prefix: any;
  sms: any = '';
  voice: any = false;
  step = 1;

  url;
  constructor(
      @Attribute('mask') pattern: string,
      public navCtrl: NavController,
      private platform: Platform,
      private httpApi: HttpApiService,
      private alertCtrl: AlertController,
      private translate: TranslateService,
      private loadingCtrl: LoadingController,
      private splashScreen: SplashScreen,
      public userServ: UserService,
      public pushServ: PushService,
      private storage: Storage) {
    // this.pattern = pattern;
    // this.cancel = translate.instant("CANCEL");
    // this.error = translate.instant("CODE_NOT_VALID");
    // this.phoneerror = translate.instant("PHONE_ERROR");
  }

  ionViewDidEnter() {





    if (this.platform.is('cordova')) {

      this.platform.ready().then((readySource) => {


      });
    }




  }

  // console.log('ionViewDidLoad LoginPhonePage');


  async phoneLogin() {

    // tslint:disable-next-line:triple-equals
    if (!this.phone || this.phone == '' || this.phone.length < 9) {
      // @ts-ignore
      const alert = await this.alertCtrl.create({

        header: this.translate.instant('ERROR'),
        message: this.translate.instant('ERROR_POHNE'),
        buttons: [{
          text: this.translate.instant('CANCEL'),
          role: 'cancel',
        }]
      });
      await alert.present();
      return true;
    }

    if (!this.code || this.code === '' || this.code.length < 5) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant('ERROR'),
        message: this.translate.instant('ERROR_CODE'),
        buttons: [{
          text: this.translate.instant('CANCEL'),
          role: 'cancel',
        }]
      });
      await alert.present();
      return true;
    }


    this.userServ.submitPhone(this.phone, this.code, this.sms, this.voice, this.prefix).then(async (res: UserInfo) => {

      if (!res) {
        const alert = await this.alertCtrl.create({
          header: this.translate.instant('ERROR'),
          buttons: [{
            text: this.translate.instant('CANCEL'),
            role: 'cancel',

          }]
        });
        await alert.present();
      }
      if (!res.userid) {
        this.step = 2;
        return true;
      } else {
        console.log(res);
        if (res.userid) {
          this.storage.set('user', res);
          this.userServ.loginToken(res).then(rr => {
            if (this.userServ.userInfo.type === 'dietician' || this.userServ.userInfo.type === 'groupmanager') {
              this.navCtrl.navigateRoot('home/dashboard-users');
            } else {
              this.navCtrl.navigateRoot('home/dashboard');
            }
          });
          //  console.log(this.storage);
        }
      }
    });
  }

  public logout() {
    this.pushServ.unsubscribeAllTopics(this.userServ.userInfo.userid);
    this.userServ.removePushToken();
    this.storage.remove('user');
    this.storage.remove('fbtoken');
    this.storage.clear();

    this.userServ.logout();


    firebase.auth().signOut().then(() => {
      this.userServ.login = false;
      this.deleteAllCookies();
      this.storage.clear().then(() => {
        if (this.platform.is('cordova')) {
          this.navCtrl.navigateRoot('/welcome');
          // exit(0);
        } else {
          window.location.reload(true);
        }
      });
      // this.navCtrl.navigateRoot('welcome');
    });




  }
  public deleteAllCookies() {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    });
  }

  onPinCodeComplete(field, value) {
    this[field] = value;
  }
}
