import { Component, OnInit } from '@angular/core';
import {NavController, Platform, PopoverController} from '@ionic/angular';
// import {User} from 'firebase';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-attach',
  templateUrl: './attach.page.html',
  styleUrls: ['./attach.page.scss'],
})
export class AttachPage {

  constructor(public popoverController: PopoverController, public platform: Platform,
              public userServ: UserService) { }

  attach(item) {
    this.popoverController.dismiss({attach: item});
  }

}
