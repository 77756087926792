import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {HttpApiService} from './http-api.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import {Storage} from '@ionic/storage';
import {PushService} from './push.service';
import {AlertController, Platform} from '@ionic/angular';


const USER_DATA = '';
export class UserInfo {
  id ? = '0';
  userid = '0';
  token = '';
  isAdmin ? = false;
  name = '';
  firebase: string;
  avatar?: string;
  type?: string;
  groups?: any;
  consultation?: any[];
  weight?: number;
  trace?: string;
  tosAgree: any;
}


@Injectable({
  providedIn: 'root'
})

export class UserService {
  userInfo: UserInfo;
  public login = false;
  myUsers;
  public analytics: any[] = [];
  public users: any = [];
  public newsBadge = 0;
  public newsRead = [];
  public isDesktop = false;
  constructor(private http: HttpClient,
              private storage: Storage,
              public pushServ: PushService,
              public platform: Platform,
              public httpApi: HttpApiService,
              public translate: TranslateService,
              public alertCtrl: AlertController
  ) {
    this.storage.get('msgRead').then(arr => { this.newsRead = arr || []; });
    if (!this.platform.is('ios') && !this.platform.is('android') ) {
      this.isDesktop = true;
    }
    if (this.platform.is('mobileweb')  ) {
      this.isDesktop = true;
    }
  }


  public loginToken(user: UserInfo) {
    if (user && user.token.length > 0) {
      this.userInfo = user;
      const data = {
        option: 'com_archat_api',
        task: 'post_api',
        func: 'login_token'
      };
      const payloadData = {
        userid: user.userid,
        token: user.token

      };
      // @ts-ignore
      return new Promise(resolve => {
        this.httpApi.post('ARCHAT', data, payloadData, false).then((res: UserInfo) => {
          console.log(res);
          if (!res || !res.userid) {
            resolve(res);
            return;
          }
          this.storage.set('user', res);
          // console.log(user);
          if (user.name) {
            // res.name = user.name;
          }
          if (user.avatar) {
            // res.avatar = user.avatar;
          }
          console.log(res);
          this.userInfo = res;
          this.pushServ.isLogin = true;

          // this.getAnalytics();
          this.hit('login');
          this.getTosMsg();
          if (this.userInfo.type === 'dietician' || this.userInfo.type === 'groupmanager') {
            this.getMyUsers();
          } else {
            this.userInfo.type = 'user';
          }
          this.updateMyToken();
          firebase.auth().signInWithCustomToken(res.firebase).then(fb => {
            // console.log(fb);
            this.getUsers(user);
            this.pushServ.firstSubscribeToTopic('USER_' + res.userid, res.userid);
            resolve(res);
          });
        });
      });
    }
  }

  getTosMsg() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'tos_msg'
    };
    this.httpApi.get('ARCHAT', data, {}, false).then(async (res: any) => {
      if ( res.last_tos_msg !== res.id) {
        const alert = await this.alertCtrl.create({

          header: res.message,
          buttons: [{
            text: this.translate.instant('OK'),
            role: 'cancel',

          }]
        });
        alert.onWillDismiss().then(() => { this.updateTosMsg(res.id); });
        alert.present();
      }
    });

  }

  updateTosMsg(id) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'update_tos_msg',
      id
    };
    this.httpApi.get('ARCHAT', data, {}, false);
  }

  public submitPhone(phone, code, sms, voice, prefix) {

    // tslint:disable-next-line:triple-equals
    const v = voice == true ? 1 : 0;
    // tslint:disable-next-line:triple-equals
    const sm = sms == '' ? 0 : sms;
    const data = {
      option: 'com_archat_api',
      task: 'post_api',
      func: 'login_phone'
    };
    // if(!sms)
    //     sms = 0;
    const payloadData = {
      phone,
      code,
      sms,
      voice,
      prefix
    };
    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.post('ARCHAT', data, payloadData, false)
          .then((res: UserInfo) => {
            this.userInfo = res;
            this.updateMyToken();
            resolve(res);
          });
    });
  }
  public logout() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'logout',
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        this.userInfo = null;
        this.storage.clear();
        resolve(res);
      });
    });

  }


  public getAvatars() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_profile_imgs',
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }



  public getArticle(articleId) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_content',
      id: articleId,
      tmpl: 'component'

    };
    // console.log(articleId);

    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }


  public addMsg(msgForm) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'add_msg',
      groups: msgForm.groups,
      title: msgForm.title,
      message: msgForm.message,
      userid: this.userInfo.userid
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }
  public report(contactForm) {

    console.log(contactForm);
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'report'

    };
    const payload = {
      messageId: contactForm.messageid,
      user: contactForm.user,
      subject: contactForm.subject,
      group: contactForm.group,
      about: encodeURIComponent(contactForm.message),
      writer: contactForm.writer,
      reason: Array.isArray(contactForm.reason) ? 0 : contactForm.reason,
      message: encodeURIComponent(contactForm.notes),

    };

    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.post('ARCHAT', data, payload, false).then(res => {
        resolve(res);
      });
    });
  }
  public contact(contactForm) {
    // console.log(contactForm);
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'send_mail'
    };
    const payload = {
      user: contactForm.user,
      subject: contactForm.subject,
      group: contactForm.group,
      name: this.userInfo.name,
      mail: this.userInfo.userid,
      message: contactForm.notes,
      about: contactForm.message,
      reason: contactForm.reason ? this.translate.instant(contactForm.reason) : ''
    };

    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.post('ARCHAT', data, payload, false).then(res => {
        resolve(res);
      });
    });
  }

  public updateName(name) {
    this.userInfo.name = name;
    // this.storage.set("user",this.userInfo);
    // console.log('users/'+this.userInfo.userid+'/profile');
    // firebase.database().ref('users/'+this.userInfo.userid+'/profile').set(this.userInfo);
    this.updateProfile();
  }
  public updateAvatar(img) {
    console.log(img);
    this.userInfo.avatar = img;
    return new Promise(resolve => {
      this.updateProfile().then(res => {resolve(res); });
    });
  }
  public updateProfile() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'updateUserDetailes',
      //    userid: this.userInfo.userid,
      //   token: this.userInfo.token,
      //   name: this.userInfo.name,
      avatar: this.userInfo.avatar
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }
  // public updateAnalytics(value) {
  //   // @ts-ignore
  //   const data = {
  //     // @ts-ignore
  //     date: new Date().toJSON().slice(0, 16),
  //     // @ts-ignore
  //     timestamp: Date.now(),
  //     value: value.val
  //   };
  //   firebase.database().ref('users/' + this.userInfo.userid + '/analytics').push(data);
  // }

  // public editAnalytics(key, value) {
  //   // @ts-ignore
  //   const data = {
  //     // @ts-ignore
  //     date: new Date().toJSON().slice(0, 16),
  //     // @ts-ignore
  //     timestamp: Date.now(),
  //     value: value.val
  //   };
  //   firebase.database().ref('users/' + this.userInfo.userid + '/analytics/key').set(data);
  // }
  //
  //
  // public getAnalytics() {
  //   const ref = firebase.database().ref('users/' + this.userInfo.userid + '/analytics');
  //   ref.on('value', result => {
  //     //  console.log(1);
  //     //  console.log('/chats/'+this.user.id+'/'+this.toUser.id+'/msg');
  //     // console.log(this.userProv.userInfo);
  //     this.analytics = this.toArray(result.val());
  //
  //   });
  // }

  public getNews() {
    // console.log('getNews');
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_news',
      //  userid: this.userInfo.userid,
      //  token: this.userInfo.token
    };

    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res) => {
        this.newsBadge = 0;
        Object.keys(res).map(i => {
          // console.log(this.newsRead);
          if (this.newsRead.includes(i)) {
          } else {
            this.newsBadge++;
          }
        });
        resolve(res);
      });
    });
  }


  public getTips() {
    // console.log('getNews');
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_tips',
      //  userid: this.userInfo.userid,
      //  token: this.userInfo.token
    };

    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res) => {
        resolve(res);
      });
    });
  }



  public getGroup(user: UserInfo, group) {
    if (user) {
      const data = {
        option: 'com_archat_api',
        task: 'api',
        func: 'get_group',
        //  userid: user.userid,
        //  token: user.token,
        group
      };
      // console.log(user);
      // @ts-ignore
      return new Promise(resolve => {
        this.httpApi.post('ARCHAT', data, {}, false).then((res) => {
          // console.log(res);
          resolve(res);

        });
      });
    }



  }

  public getUsers(user: UserInfo) {
    if (user) {
      const data = {
        option: 'com_archat_api',
        task: 'api',
        func: 'get_users',
        //  userid: user.userid,
        //  token: user.token
      };
      // console.log(user);
      // @ts-ignore
      return new Promise(resolve => {
        this.httpApi.get('ARCHAT', data, {}, false).then((res) => {
          // console.log(res);
          this.users = this.toArray(res);
          // this.updateProfiles();
          resolve(res);

        });
      });
    }

  }
  public getMyUsers() {
    if (this.userInfo.type !== 'dietician' && this.userInfo.type !== 'groupmanager') {
      return false;
    }
    const data = {
      option: 'com_archat_api',
      task: 'api',
      func: 'get_dietician_users',
      //  userid: this.userInfo.userid,
      //  token: this.userInfo.token
    };
    // console.log(user);
    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res) => {
        this.myUsers = res;
        resolve(res);

      });
    });
  }
  public toArray(obj): any[] {
    const arr = [];
    if (obj) {
      // @ts-ignore
      Object.keys(obj).forEach(key => {
        // @ts-ignore
        arr.push(obj[key]);
      });
    }
    return arr;
  }

  hit(type) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'statistics',
      func: 'hit',
      type,
      //  userid: this.userInfo.userid
    };
    this.httpApi.get('ARCHAT', data, {}, false);
  }
  removePushToken() {
    this.storage.get('fbtoken').then(token => {
      const data = {
        option: 'com_archat_api',
        task: 'api',
        mod: 'push',
        func: 'remove_pushtoken',
        // userid: this.userInfo.userid,
        //  token: this.userInfo.token,
        fbtoken: token
      };

      this.httpApi.get('ARCHAT', data, {}, false);
    });
  }
  updateMyToken() {
    console.log('updateMyToken');
    this.pushServ.getToken();
    let system = 'browser';
    if (this.platform.is('android')) { system = 'android'; }
    if (this.platform.is('ios')) { system = 'iphone'; }
    this.storage.get('fbtoken').then(token => {
      const data = {
        option: 'com_archat_api',
        task: 'api',
        mod: 'push',
        func: 'update_pushtoken',
        //   userid: this.userInfo.userid,
        //   token: this.userInfo.token,
        system,
        fbtoken: token
      };

      this.httpApi.get('ARCHAT', data, {}, false);
    });
  }
}
