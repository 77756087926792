import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { LoginPage } from './login.page';
import {TranslateModule} from '@ngx-translate/core';
import {Angular7PincodeModule} from 'angular7-pincode';
import {PincodeComponent} from '../../../components/pincode/pincode.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        Angular7PincodeModule,
    ],
    declarations: [LoginPage, PincodeComponent]
})
export class LoginPageModule {}
