import { Component, OnInit, Input, ViewChildren, ElementRef, QueryList, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
})
export class PincodeComponent implements OnInit {
  @ViewChildren('otpInput') allOtpInputs: QueryList<ElementRef>;
  @Output() onFullFill = new EventEmitter<string>();
  @Input() size = 4;
  @Input() class = '';
  Pin: any[] = [];
  PinCode = '';
  currentIndex = 0;

  constructor() { }

  ngOnInit() {
    this.GetPin();
  }
  GetPin() {
    for (let i = 0; i < this.size; i++) {
      this.Pin.push("");
    }
  }
  onFocus(event: any){
    console.log(1);
  }
  onKeyUp(event: any, index: any) {
    let key: any = event.keyCode || event.which;
    if (key !== 8 && index != this.size - 1) {
      this.currentIndex = index + 1;
      setTimeout(() => {
        this.allOtpInputs.toArray()[index + 1].nativeElement.focus();
      }, 10)
    }
    setTimeout(() => {
      this.PinCode = this.Pin.join('');
      this.onFullFill.emit(this.PinCode);
    })
  }
  onKeyDown(event: any, index: any) {
    let key: any = event.keyCode || event.which;
    if (key === 8 && index != 0) {
      setTimeout(() => {
        this.currentIndex = index - 1;
      }, 10)
      setTimeout(() => {
        this.allOtpInputs.toArray()[index - 1].nativeElement.focus();
      }, 100)
    }
  }
  trackByFn(index: any) {
    return index;
  }

}
