import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AlertController, LoadingController, NavController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../environments/environment';
const BASE_URL = environment.serverBaseHref;
import {Storage} from '@ionic/storage';

/*
  Generated class for the HttpApiProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
export class Res {
  status?: number;
  value?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {

  public token = 0;
  public phone;
  public restart = false;
  // public loading;
  public count = 0;
  headers: HttpHeaders;
  constructor(public http: HttpClient,
              public storage: Storage,
              public loadingCtrl: LoadingController,
              public alertCtrl: AlertController,
              public toastCtrl: ToastController,
              public navCtrl: NavController,
              public translate: TranslateService) {



  }

  public post(server, data= {}, dataArray= {}, showError = true) {
    let headers: HttpHeaders;
    headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', 'https://app.makshivim.net/api');
    headers.append('Access-Control-Allow-Credentials', 'true');

    headers.append('Access-Control-Allow-Methods', 'OPTIONS, GET, POST, PUT');
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, Accept, Access-Control-Allow-Origin');



    return new Promise(resolve => {
      let url = '';
      // tslint:disable-next-line:forin
      for (const key in data) {
        url += key + '=' + data[key] + '&';
      }
      this.http.post(BASE_URL + '/index.php?p=' + Math.floor(Math.random() * 1001) + '&' + url,
          dataArray,  {headers, withCredentials: true}).subscribe((res: Res) => {

            if (res.status > 0) {
              this.restart = false;
              resolve(res.value);
              if (res.message) {
                this.presentToast(res.message);
              }
            } else {
              this.error(res.message);
              if (res.status === -999) {
                this.restart = true;
              }
            }
            // if (show_loader){
            //
            //   loading.dismiss().catch(() => {});
            //
            // }
            // else
            //    resolve (this.get(server, data, dataArray, show_loader));

          },
          error => {
            console.log(error);
            // if( show_loader) {
            //   loading.dismiss().catch(()=>{});
            // }
            if (showError === true) {
              this.count++;
              if (this.count > 3) {
                this.error(this.translate.instant('NO_SERVICE'));
                this.count = 0;
              }
            }
          });


    });
  }
  // tslint:disable-next-line:variable-name
  public get(server, data= {}, dataArray= {}, show_loader, showError = true) {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', 'https://app.makshivim.net/api');
    headers.append('Access-Control-Allow-Credentials', 'true');

    headers.append('Access-Control-Allow-Methods', 'OPTIONS, GET, POST');
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, Accept, Access-Control-Allow-Origin');


    const loading = this.loadingCtrl.create({});
    if (show_loader) {

      //  loading.present();

    }
    let url = '';
    // tslint:disable-next-line:forin
    for (const key in data) {
      url += key + '=' + data[key] + '&';
    }
    // tslint:disable-next-line:forin
    for (const key in dataArray) {
      url += 'data[' + key + ']=' + dataArray[key] + '&';
    }
    // console.log('ppp');
    return new Promise(resolve => {
      this.http.get(BASE_URL + '/index.php?p=' + Math.floor(Math.random() * 1001) + '&' + url,
          {headers, withCredentials: true}).subscribe((res: Res) => {
            if (res.status > 0) {
              this.restart = false;
              resolve(res.value);
              if (res.message) {
                this.presentToast(res.message);
              }
            } else {

              if (res.status === -999) {
                this.restart = true;
                //  this.navCtrl.navigateRoot('/welcome');

              } else {
                this.error(res.message);
              }
            }
            if (!res) {
              this.restart = true;
            }
            // if (show_loader){
            //
            //   loading.dismiss().catch(() => {});
            //
            // }
            // else
            //    resolve (this.get(server, data, dataArray, show_loader));

          },
          error => {
            console.log(error);
            this.restart = true;
            // if( show_loader) {
            //   loading.dismiss().catch(()=>{});
            // }
            if (showError === true) {
              this.count++;
              if (this.count > 3) {
                this.error(this.translate.instant('NO_SERVICE'));
                this.count = 0;
              }
            }
          });


    });

  }

  public async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top'
    });
    await toast.present();
  }

  public async error(error) {

    const alert = await this.alertCtrl.create({
      //  title: this.translateW["ERROR_ADDRESS"],
      message: error,
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',

      }]
    });
    if (error.length > 0) {
      await alert.present();
    }
  }
}
