import { Component } from '@angular/core';
import {ModalController, NavController, NavParams, PopoverController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.page.html',
  styleUrls: ['./submenu.page.scss'],
})
export class SubmenuPage  {

  list: any = [];
  constructor( public navCtrl: NavController,
               private navParams: NavParams,
               public popoverController: PopoverController) {
    // console.log(this.navOpt);
  }

  ionViewDidEnter() {
    this.list = this.navParams.get('list');
    // console.log(this.list);
  }
  open(item) {


    if (item.func ) {
      this.popoverController.dismiss({func: item.func, param:  item.selectedItem});
    }

    console.log(item);
  }


}
