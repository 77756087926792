import { Component, OnInit } from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss'],
})
export class SelectIconComponent implements OnInit {
  public icons = [
      'mood', 'etv', 'tv', 'movie', 'dog', 'sun', 'flight',
      'book', 'headphone', 'music', 'track', 'train', 'bycl',
      'Shape', 'drink1', 'breakfast', 'hotellocal_hotel',
      'local_cafefree_breakfast', 'directions_run1',  'drink', 'food'
  ];
  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}
  selected(icon) {
      this.popoverController.dismiss({icon});
  }

}
