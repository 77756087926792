import { Component, OnInit } from '@angular/core';
import {AlertController, NavController} from '@ionic/angular';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ChatService} from '../../services/chat.service';

@Component({
  selector: 'app-add-msg',
  templateUrl: './add-msg.page.html',
  styleUrls: ['./add-msg.page.scss'],
})
export class AddMsgPage implements OnInit {
  public class;
  public msgForm: FormGroup;
  output: any;
  constructor( public navCtrl: NavController,
               private userServ: UserService,
               public alertCtrl: AlertController,
               public translate: TranslateService,
               private fb: FormBuilder,
               public chatServ: ChatService,
               private route: ActivatedRoute) {
    this.msgForm = fb.group({
      groups: new FormControl([], [Validators.required, Validators.minLength(1) ]),
      title: new FormControl([], [Validators.required, Validators.minLength(1) ]),
      message: new FormControl([], [Validators.required, Validators.minLength(1) ]),
      userid: this.userServ.userInfo.userid
    });
  }

  ngOnInit() {
  }

  get groups() {
    return this.msgForm.get('groups') as FormControl;
  }


  async send() {
    if (this.msgForm.valid) {
      this.userServ.addMsg(this.msgForm.value)
          .then(async (res) => {
           // console.log(res);
            this.output = res;
            const alert = await this.alertCtrl.create({

              header: this.output,
              buttons: [{
                text: this.translate.instant('CLOSE'),
                role: 'cancel',

              }]
            });
            alert.present();
            this.navCtrl.back();
          });
    } else {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant('REQIRED_ERROR'),
        buttons: [{
          text: this.translate.instant('CANCEL'),
          role: 'cancel',

        }]
      });
      alert.present();
    }

  }
  dismiss() {
    this.navCtrl.back();
  }
}
