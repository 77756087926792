import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {HttpApiService} from './http-api.service';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage';
import {NavController} from '@ionic/angular';

enum taskValueType {
  'twoHours', 'times', 'minutes', 'hours'
}

export class Task {
  id ? = 0;
  taskid ? = 0;
  title = '';
  type = '';
  valueType: string;
  defaults: TaskSetting;
  value?: any;
  img?: string;
  points?: number;
  description?: string;
  bonus?: string[];
  open ? = false;
  done ? = false;
  finish ? = false;
  articleId ? = 0;
  bonusDone?: TaskDone[] = [];
  custom = false;
  icon?: string;
  day?: number;
}
export class TaskDone {
  name: string;
  done: boolean;
}
export class TaskSetting {
  timeStart?: string;
  timeEnd?: string;
  sendPush: boolean;
  pushTime?: string;
  times?: number;
  minutes?: number;
  hours?: number;
  time?: string;
  title?: string;
  icon?: string;
  custom?: boolean;
  day?: number;
  date?: number;
}
@Injectable({
  providedIn: 'root'
})
export class TasksService {

  public myTasks: Task[] = [];
  public customTasks: Task[] = [];
  public myTasksToday: Task[] = [];
  public myPoints = 0;
  public destPoints = 0;
  public myPointsTable;
  public myTasksHistory;
  constructor(private http: HttpClient,
              private storage: Storage,
              private userServ: UserService,
              public httpApi: HttpApiService,
              private navCtrl: NavController,
              public translate: TranslateService) {
     this.getTasks();
     this.getCustomTasks();
     this.getPoints();
     this.getPointsTable();
     this.getTasksHistory();
    // console.log('Hello TasksProvider Provider');

  }

  getTasksToday() {
    if (!this.userServ.userInfo) {
      this.navCtrl.navigateRoot('welcome');
      return;
    }
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_tasks_today',
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        //console.log(res);
        this.myTasksToday = res.sort((a, b) => (a.id > b.id) ? 1 : -1);

        // this.myTasksToday.forEach((value, key) => {
        //
        //   if(this.myTasksToday[key].bonusDone.length == 0){
        //     value.bonus.forEach(v=>{
        //       this.myTasksToday[key].bonusDone.push({name: v, done: false});
        //     });
        //   }
        //
        // });
        // console.log(this.myTasksToday);
        resolve(res);
      });
    });
  }


  getTasks() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_tasks',
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        this.myTasks = res;
        console.log(res);
    //    this.customTasks = res.values().filter(t => t.custom);
        // this.myTasksArr = this.toArray(res);
        resolve(res);
      });
    });
  }
  getTasksTypes() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_tasks_types'
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        console.log(res);
        resolve(res);
      });
    });
  }
  getCustomTasks() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_custom_tasks',

    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        this.customTasks = res;
        console.log(this.customTasks);
        //    this.customTasks = res.values().filter(t => t.custom);
        // this.myTasksArr = this.toArray(res);
        resolve(res);
      });
    });
  }
  getPoints() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_points',

    };

    this.httpApi.get('ARCHAT', data, {}, false).then((res: number) => {
      this.myPoints = res || 0;
    });
    const data2 = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_points_dest',

    };

    this.httpApi.get('ARCHAT', data2, {}, false).then((res: number) => {
      this.destPoints = res;
    });
  }


  getPointsTable() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_points_table',

    };
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: number) => {
        this.myPointsTable = res;
      });
      resolve(1);
    });
  }
  getTasksHistory() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'get_tasks_history',

    };
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: number) => {
        this.myTasksHistory = res;
      });
      resolve(1);
    });
  }
  addAll() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'add_all_tasks'
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.post('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }
  setTask(taskObject: Task, taskSettings: TaskSetting) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'set_task',

      taskid: taskObject.taskid,
      type: taskObject.type,
      day: taskObject.day,
      custom: taskObject.custom ? 1 : 0,
      task_data: JSON.stringify(taskSettings)
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(res => {
        resolve(res);
      });
    });
  }
  setTaskDone(task: Task) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'set_task_done',

      taskid: task.id,
      type: task.type,
      bonus: JSON.stringify(task.bonusDone)

    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        // this.getTasksToday();
        this.myTasksToday = res.sort((a, b) => (a.id > b.id) ? 1 : -1);
        resolve(res);
      });
    });
  }
  deleteTask(taskObject: Task) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'delete_task',

      taskid: taskObject.taskid,
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
        this.myTasks = res;
        this.getCustomTasks();
        resolve(res);
      });
    });
  }

  public toArray(obj): any[] {
    const arr = [];

    if (obj) {
      Object.keys(obj).forEach(key => {
        obj[key].title = key.toLocaleUpperCase();
        obj[key].description = key.toLocaleUpperCase() + '_DESC';
        obj[key].points = 5;
        arr.push(obj[key]);
      });
    }

    return arr;
  }

  public updateToken(token) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'tasks',
      func: 'update_token',
      fcm: token,
    };


    // @ts-ignore

    this.httpApi.get('ARCHAT', data, {}, false).then((res: Task[]) => {
      this.myTasks = res;
      // this.customTasks = res.filter(t => t.custom);

    });

  }

}
