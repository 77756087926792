// @ts-ignore
import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {FileTransfer, FileTransferObject, FileUploadOptions} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HttpApiService} from './http-api.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  base64img: string;
  url = environment.serverBaseHref + '/index.php';
  public loading: any;
  constructor(
      public http: HttpClient,
      public httpApi: HttpApiService,
      private file: File,
      public loadingController: LoadingController,
      private transfer: FileTransfer) {

  }

  setImage(img) {
    this.base64img = img;
    //  console.log(this.base64img);
  }

  getImage() {
    return this.base64img;
  }
  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: '',
      duration: 30000
    });
    await this.loading.present();
  }
  uploadImage(image) {
    console.log(image);

    this.presentLoading();
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append('file', image, image.name || 'p.jpg');
      this.http.post(
          environment.serverBaseHref + '/index.php?option=com_archat_api&task=uploadimg&func=getUpload&api=1',
          formData,
          {responseType: 'text', withCredentials: true}).subscribe((res: any) => {
        this.loading.dismiss();
        //     console.log(res);
        resolve(res);
      });
    });
  }

  async uploadPic(img) {
    // const loader = await this.loadingCtrl.create({
    //   content: 'Uploading....'
    // });
    // loader.present();

    this.presentLoading();
    return new Promise(resolve => {
      this.http.post(
          environment.serverBaseHref + '/index.php?option=com_archat_api&task=uploadimg&func=appUpload&api=1',
          {img: img},
          {responseType: 'text', withCredentials: true}).subscribe((res: any) => {
        this.loading.dismiss();
        //     console.log(res);
        resolve(res);
      });
    });
    // const fileTransfer: FileTransferObject = this.transfer.create();
    // const options: FileUploadOptions = {
    //   fileKey: 'file',
    //   fileName: 'appUpload.jpg',
    //   chunkedMode: false,
    //   mimeType: 'image/jpeg',
    //   headers: {}
    // };
    // console.log(options);
    // return new Promise(resolve => {
    //   fileTransfer.upload(img, environment.serverBaseHref + '/index.php?option=com_archat_api&task=uploadimg&func=appUpload'
    //       , options).then(data => {
    //     console.log(data.response);
    //     this.loading.dismiss();
    //     // alert(data);
    //     resolve(JSON.parse(data.response).value);
    //     // loader.dismiss();
    //
    //   }, error => {
    //     console.log(error);
    //
    //     console.log('error' + JSON.stringify(error));
    //     // loader.dismiss();
    //   }).catch(err => {
    //     console.log(err);
    //   });
    // });






  }

}
