import { Injectable } from '@angular/core';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {NavController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Survey} from './survey.service';
import {UserService} from './user.service';
import {HttpApiService} from './http-api.service';
import {HttpClient} from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable({
    providedIn: 'root'
})
export class PushService {
    myTopics = [];
    public redirect = '';
    public isLogin;
    constructor(private firebasex: FirebaseX,
                public platform: Platform,
                private navCtrl: NavController,
                private httpApi: HttpApiService,
                private storage: Storage) {
        this.getToken();
        this.getMyTopics();

    }
    getToken() {
        //   console.log('getToken', this.platform);
        if ( !this.platform.is('android') && !this.platform.is('ios')) {
            const messaging = firebase.messaging();

            navigator.serviceWorker.register('/assets/firebase-messaging-sw.js')
                .then((registration) => {
                    // console.log(registration);
                    // messaging.useServiceWorker(registration);
                    // messaging.requestPermission().then(() => {
                    //
                    //     messaging.getToken().then(token => {
                    //         // console.log(token);
                    //         this.storage.set('fbtoken', token);
                    //     });
                    //
                    // });
                });
        } else {
            this.firebasex.hasPermission().then(hasPermission => {
                if (hasPermission === false) {
                    this.firebasex.grantPermission().then((permission) => {
                        console.log('Permission was ' + (permission ? 'granted' : 'denied'));
                    });
                }
            });
            // console.log('getToken');
            this.firebasex.getToken()
                .then(token => {
                    console.log('TOKEN:' + token);
                    this.storage.set('fbtoken', token);
                });
            this.firebasex.onMessageReceived().subscribe(msg => {
                // this.firebase.clearAllNotifications();
                // console.log('MSG: ', msg);
                // console.log('TAP: ', msg.tap);
                //
                if (msg.tap === 'background') {
                    if (this.isLogin) {
                        this.redirect = '';
                        this.navCtrl.navigateForward(msg.url);
                    } else {
                        this.redirect = msg.url;
                    }
                }


            });
        }
    }

    getMyTopics() {
        this.storage.get('topics').then(topics => {
            if (topics) {
                this.myTopics = topics;
                //  console.log(this.myTopics);
            }
        });
    }
    subscribeToTopic(topic, userid) {
        // console.log(topic);
        //    if (this.platform.is('cordova')) {
        //        this.firebase.subscribe(topic).then(res => {
        //            console.log(res);
        //            this.updateMyTopics(topic, true);
        //        });
        //    }

        const data = {
            option: 'com_archat_api',
            task: 'api',
            mod: 'push',
            func: 'subscribe',
            topic
        };
        this.httpApi.get('ARCHAT', data, {}, false).then(() => this.updateMyTopics(topic, true));
    }
    unsubscribeToTopic(topic, userid) {
        //  console.log(topic);
        //     if (this.platform.is('cordova')) {
        //         this.firebase.unsubscribe(topic).then(res => {
        //             console.log(res);
        //             this.updateMyTopics(topic, false);
        //         });
        //     }
        const data = {
            option: 'com_archat_api',
            task: 'api',
            mod: 'push',
            func: 'unsubscribe',
            topic
        };
        this.httpApi.get('ARCHAT', data, {}, false).then(() => this.updateMyTopics(topic, false));
    }

    updateMyTopics(name, value) {
        const newTopics = this.myTopics.filter(t =>  t.name !== name) || [];
        newTopics.push({name, value });
        this.storage.set('topics', newTopics).then(() => {
            this.getMyTopics();
        });
    }

    firstSubscribeToTopic(topic, user) {
        const hassubscribe = this.myTopics.filter(t =>  t.name === topic);
        if (!hassubscribe || hassubscribe.length === 0 || hassubscribe[0].value === true) {
            this.subscribeToTopic(topic, user);
        }
    }

    unsubscribeAllTopics(userid) {
        this.myTopics.map(topic => {
            this.unsubscribeToTopic(topic.name, userid);
        });
    }
}
