import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user.service';
import {HttpApiService} from './http-api.service';

export class Question {
  type: string;
  question: string;
  additionaltext?: string;
  // tslint:disable-next-line:variable-name
  option_1?: string;
  // tslint:disable-next-line:variable-name
  option_2?: string;
  // tslint:disable-next-line:variable-name
  option_3?: string;
  // tslint:disable-next-line:variable-name
  option_4?: string;

}

export class Survey {
  title: string;
  id: number;
  questions: string;
  daysafter: string;
}
@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public mySurveies: Survey[] = [];
  public allSurveies: Survey[] = [];
  constructor(private http: HttpClient,
              private storage: Storage,
              private userServ: UserService,
              public httpApi: HttpApiService,
              public translate: TranslateService ) {
    if (this.userServ.userInfo) {
      this.getMySurvey();
    }
  }

  getAllSurvey() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'survey',
      func: 'get_all_survey',
      // userid: this.userServ.userInfo.userid,
      // token: this.userServ.userInfo.token
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Survey[]) => {
        this.allSurveies = res;
     //   console.log(res);
        resolve(res);
      });
    });
  }

  getMySurvey() {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'survey',
      func: 'get_my_survey',
      // userid: this.userServ.userInfo.userid,
      // token: this.userServ.userInfo.token
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Survey[]) => {
        this.mySurveies = res;
    //    console.log(res);
        resolve(res);
      });
    });
  }

  submitSurvey(surveyId, answer) {

    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'survey',
      func: 'submit_survey',
      surveyId,
      answers: JSON.stringify(answer),
      // userid: this.userServ.userInfo.userid,
      // token: this.userServ.userInfo.token
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Survey[]) => {
        this.getMySurvey();
        resolve(res);
      });
    });
  }
}
