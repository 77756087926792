import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { he } from 'date-fns/locale';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return formatRelative(new Date(value), new Date(), { locale: he });
  }

}
