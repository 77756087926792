import { Component, OnInit } from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {TraceService} from '../../../../services/trace.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-mood',
  templateUrl: './add-mood.page.html',
  styleUrls: ['./add-mood.page.scss'],
})
export class AddMoodPage implements OnInit {

  mood = '';
  constructor(public popoverController: PopoverController, public traceService: TraceService,
              public translate: TranslateService) { }

  ngOnInit() {
    console.log(this.traceService.moods);
  }
  // apply() {
  //   // console.log(this.mood);
  //   this.popoverController.dismiss({mood: this.mood});
  // }
  // close() {
  //    this.popoverController.dismiss({mood: false});
  //
  // }
  setMood(mood) {
   // this.mood = mood;
    this.popoverController.dismiss({mood});
  }
}
