import { Injectable } from '@angular/core';
import {HttpApiService} from './http-api.service';
import {UserService} from './user.service';

export class Trace {
  created: string;
  value: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class TraceService {
  public moods = [
    {name: 'happy', title: 'HAPPY', image: 'grinning-face_1f600.svg', icon: '😄'},
    {name: 'sad', title: 'SAD', image: 'loudly-crying-face_1f62d.svg', icon: '😭'},
    {name: 'afraid', title: 'AFRAID', image: 'face-screaming-in-fear_1f631.svg', icon: '😱'},
    {name: 'tired', title: 'TIRED', image: 'sleeping-face_1f634.svg', icon: '😴'},
    {name: 'angry', title: 'ANGRY', image: 'pouting-face_1f621.svg', icon: '😡'},
    {name: 'sick', title: 'SICK', image: 'face-with-thermometer_1f912.svg', icon: '🤒'},
  ];
  public trace: Trace[] = [];
  constructor(private httpApi: HttpApiService, private userServ: UserService) {
    if (this.userServ.userInfo) {
      this.getTrace('weight');
    }
  }



  getTrace(type) {
    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'trace',
      func: 'get_my_trace',
      type,
      // userid: this.userServ.userInfo.userid,
      // token: this.userServ.userInfo.token
    };

    return new Promise(resolve => {
    // @ts-ignore
      this.httpApi.get('ARCHAT', data, {}, false).then((res: Trace[]) => {
          this.trace = res;
          resolve(1);
      });
    });
  }

  insertTrace(type, value) {

    const data = {
      option: 'com_archat_api',
      task: 'api',
      mod: 'trace',
      func: 'insert_trace',
      type,
      value,
      // userid: this.userServ.userInfo.userid,
      // token: this.userServ.userInfo.token
    };


    // @ts-ignore
    return new Promise(resolve => {
      this.httpApi.get('ARCHAT', data, {}, false).then(() => {
        this.getTrace(type);
        resolve(1);
      });
    });
  }

}
